import * as React from 'react'
import { Router } from '@reach/router'

import MultiDraw from '@components/MultiDraw'
import { RoomWrapper } from '@components/Room'
import { RoomType } from '@stores/models/Room'

const MultiPage = () => {
  return (
    <Router>
      <RoomWrapper path="/multi/:roomId" type={RoomType.MULTI} waitForInvited>
        <MultiDraw path="/" />
      </RoomWrapper>
    </Router>
  )
}

export default MultiPage
