/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import * as React from 'react'
import { useGesture } from 'react-use-gesture'
import useWindowSize from '@hooks/useWindowSize'
import { useStore } from '@stores/useStore'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Navigation, NavButton } from '@components/Navigation'
import { Debug } from '@components/Debug'
import Header from '@components/Header'

const SMALLEST_LINEWIDTH = 1

const getWidthName = (width: number) => {
  switch (width) {
    default:
    case SMALLEST_LINEWIDTH:
      return 'Small'
    case 16:
      return 'Medium'
    case 72:
      return 'Large'
  }
}

export const splitLine = (a, b, count) => {
  count = count + 1
  const d = Math.hypot(a[0] - b[0], a[1] - b[1]) / count
  const fi = Math.atan2(b[1] - a[1], b[0] - a[0])

  const points = []

  for (let i = 0; i <= count; i++) {
    points.push([a[0] + i * d * Math.cos(fi), a[1] + i * d * Math.sin(fi)])
  }

  return points
}

const getCoord = (point, offset) => {
  return [point[0] - offset[0], point[1] - offset[1]]
}

const Canvas = observer(() => {
  const room = useStore()
  const link = React.useRef(null)
  const context = React.useRef(null)
  const canvas = React.useRef(null)
  const [active, setActive] = React.useState(false)
  const [lineWidth, setLineWidth] = React.useState(SMALLEST_LINEWIDTH)
  const [offset, setOffset] = React.useState([0, 0])
  const { width: windowW, height: windowH } = useWindowSize()
  const [showSizes, setShowSizes] = React.useState(false)

  React.useEffect(() => {
    canvas.current.width = room.width * room.pixelRatio
    canvas.current.height = room.height * room.pixelRatio
    canvas.current.style.width = `${room.width}px`
    canvas.current.style.height = `${room.height}px`
    setOffset([
      (windowW - canvas.current.width / room.pixelRatio) / 2,
      (windowH - canvas.current.height / room.pixelRatio) / 2
    ])
    reset()
  }, [room.width, room.height, room.pixelRatio])

  React.useEffect(() => {
    setOffset([
      (windowW - canvas.current.width / room.pixelRatio) / 2,
      (windowH - canvas.current.height / room.pixelRatio) / 2
    ])
  }, [windowW, windowH, room.pixelRatio])

  const reset = () => {
    if (!context.current) return
    setActive(false)
    context.current.fillStyle = room.user.color
    context.current.fillRect(
      0,
      0,
      room.width * room.pixelRatio,
      room.height * room.pixelRatio
    )
    if (!room.isEmpty) {
      const halfWidth = Math.round(room.width / 2)
      context.current.fillStyle = room.drawingPartner.color
      context.current.fillRect(
        room.user.initiator ? 0 : halfWidth * room.pixelRatio,
        0,
        halfWidth * room.pixelRatio,
        room.height * room.pixelRatio
      )
    }
    context.current.beginPath()
  }

  React.useEffect(() => {
    context.current = canvas.current.getContext('2d')
    context.current.lineCap = 'round'
    context.current.lineJoin = 'round'
    context.current.miterLimit = 4
    room.setContext(context.current)
    reset()
  }, [])

  React.useEffect(() => {
    if (!context.current) return
    if (!room.isEmpty) {
      reset()
    }
  }, [room.isEmpty])

  React.useEffect(() => {
    return autorun(() => {
      context.current.fillStyle = room.user.color
      context.current.fillRect(
        0,
        0,
        room.width * room.pixelRatio,
        room.height * room.pixelRatio
      )
      if (!room.isEmpty) {
        const halfWidth = Math.round(room.width / 2)
        context.current.fillStyle = room.drawingPartner.color
        context.current.fillRect(
          room.user.initiator ? 0 : halfWidth * room.pixelRatio,
          0,
          halfWidth * room.pixelRatio,
          room.height * room.pixelRatio
        )
      }
      context.current.beginPath()
    })
  }, [])

  React.useEffect(() => {
    if (!context.current) return
    context.current.strokeStyle = room.user.color
    context.current.fillStyle = room.user.color
  }, [room.user.color])

  const handleWidthChange = React.useCallback(
    (size) => {
      setShowSizes(false)
      switch (size) {
        case 0:
          setLineWidth(SMALLEST_LINEWIDTH)
          break
        case 1:
          setLineWidth(16)
          break
        case 2:
          setLineWidth(72)
          break
        default:
          setLineWidth(3)
          break
      }
    },
    [setShowSizes, setLineWidth]
  )

  const handleMouseDown = React.useCallback(() => {
    setActive(true)
    setShowSizes(false)
    context.current.fillStyle = room.user.color
  }, [setShowSizes, setActive])

  const handleMouseUp = React.useCallback(() => {
    setActive(false)
  }, [setActive])

  const drawOnCanvas = (e) => {
    // Why was this disabled?
    // if (e.first || e.last) return

    const ctx = context.current
    const distance = Math.hypot(
      e.xy[0] - e.previous[0],
      e.xy[1] - e.previous[1]
    )
    const points = splitLine(
      getCoord(e.xy, offset),
      getCoord(e.previous, offset),
      Math.max(1, distance / (lineWidth / 5))
    )
    room.sendMessage({
      type: 'line',
      start: getCoord(e.previous, offset),
      end: getCoord(e.xy, offset),
      size: lineWidth,
      color: room.user.color,
      step: Math.max(1, distance / (lineWidth / 5))
    })
    points.forEach((p) => {
      ctx.beginPath()
      ctx.arc(
        p[0] * room.pixelRatio,
        p[1] * room.pixelRatio,
        lineWidth,
        0,
        2 * Math.PI
      )
      ctx.fill()
      ctx.closePath()
    })
  }

  const handleMouseMove = React.useCallback(
    (e) => {
      if (!active) return
      context.current.fillStyle = room.user.color
      drawOnCanvas(e)
    },
    [drawOnCanvas]
  )

  const bind = useGesture(
    {
      onDrag: (state) => handleMouseMove(state),
      onDragStart: (state) => handleMouseDown(state),
      onDragEnd: (state) => handleMouseUp(state)
    },
    {
      eventOptions: {
        pointer: false
      }
    }
  )

  const handleShow = React.useCallback(() => {
    setShowSizes(true)
  }, [setShowSizes])

  return (
    <React.Fragment>
      <Debug>
        <button onClick={() => reset()}>
          <span role="img" aria-label="reset">
            🔄
          </span>
        </button>
        <button onClick={() => handleWidthChange(0)}>S</button>
        <button onClick={() => handleWidthChange(1)}>M</button>
        <button onClick={() => handleWidthChange(2)}>L</button>
      </Debug>
      <a href="" download ref={link} style={{ visibility: 'hidden' }} />
      <canvas ref={canvas} {...bind()} />
      <Navigation show={!room.isEmpty && !active}>
        {showSizes ? (
          <div
            sx={{
              display: 'grid',
              gridTemplateRows: '1fr 1fr 1fr',
              rowGap: '1.6em'
            }}
          >
            <NavButton
              sx={{ textAlign: 'left' }}
              onClick={() => handleWidthChange(2)}
            >
              Large
            </NavButton>
            <NavButton
              sx={{ textAlign: 'left' }}
              onClick={() => handleWidthChange(1)}
            >
              Medium
            </NavButton>
            <NavButton
              sx={{ textAlign: 'left' }}
              onClick={() => handleWidthChange(0)}
            >
              Small
            </NavButton>
          </div>
        ) : (
          <NavButton onClick={handleShow}>{getWidthName(lineWidth)}</NavButton>
        )}
        <NavButton
          onClick={() => {
            link.current.setAttribute('download', 'ChromaDual-drawing.png')
            const img = canvas.current
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream')
            link.current.setAttribute('href', img)
            link.current.click()
          }}
        >
          Save
        </NavButton>
      </Navigation>
      <Header show={!active} />
      {room.drawingPartner && room.drawingPartner.disconnecting && (
        <Text
          sx={{
            position: 'absolute',
            top: '0.3em',
            left: '50%',
            textAlign: 'center',
            color: 'white',
            mixBlendMode: 'difference',
            transform: 'translateX(-50%)'
          }}
        >
          <span sx={{ textTransform: 'capitalize' }}>
            {room.drawingPartner.humanColor}
          </span>
          {` left`}
        </Text>
      )}
    </React.Fragment>
  )
})

export default Canvas
